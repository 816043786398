<template>
    <div class="p-2 bg-white border-2 rounded-2xl">
        <div class="p-2">
            <div class="border-2 mb-1 rounded-2xl w-6 flex border-gray-300"></div>
            <div>
                <div class="text-gray-600 flex justify-between">
                    <div class="w-40 pr-6 font-semibold text-xs">{{ title }}</div>
                    <div class="border flex items-center p-2 rounded-xl -mt-2 mb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"></path>
                        </svg>
                    </div>
                </div>
                <div class="flex justify-between pt-6">
                    <div class="text-sm font-bold text-gray-600 h-5 w-16">
                        <div v-if="loading" class="load-wraper w-16">
                            <div class="activity"></div>
                        </div>
                        <div v-else>
                            {{ valueFormatted }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ApiService } from '@/services/api.service';
export default {
    name: 'Card',
    props: {
        title: {
            type: String,
            default: 'Título'
        },
        value: {
            type: String,
            default: 'Valor'
        },
        endpoint: {
            type: [String, Number],
            default: ''
        }
    },
    data() {
        return {
            mutableValue: this.value,
            loading: false
        };
    },
    computed: {
        ...mapGetters('filters', ['period']),
        valueFormatted() {
            return this.mutableValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        }
    },
    watch: {
        period() {
            this.getData();
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            if (this.endpoint === '') {
                return;
            }
            this.loading = true;
            await ApiService.customRequest({ url: this.endpoint, params: { period: this.period } })
                .then(response => {
                    this.mutableValue = response.data;
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style>
.load-wraper {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: rgb(211, 211, 211);
    z-index: 44;
    overflow: hidden;
    border-radius: 5px;
}
.activity {
    position: absolute;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    background-image: -moz-linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    background-image: -webkit-linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    animation: loading 1s infinite;
    z-index: 45;
}

@keyframes loading {
    0% {
        left: -45%;
    }
    100% {
        left: 100%;
    }
}
</style>
