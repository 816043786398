<template>
    <div>
        <TopPage title="Vendas" complement="e informações" />

        <!-- Bloco de cards -->
        <div class="pb-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4">
            <div v-for="card in cards" :key="card.id">
                <Card :title="card.title" :value="card.value" :endpoint="card.endpoint" />
            </div>
        </div>

        <ColumnCard ref="chart1" :series="salesChartSeries" title="Vendas mensal acumulada" />

        <ColumnCard ref="chart2" type="percentage" :series="salesChartSeries2" class="mt-4" title="Crescimento sobre o mês anterior (%)" />

        <div class="flex">
            <div class="inline-block mt-4">
                <div v-for="provider in providers" :key="provider.value">
                    <button
                        @click="partnerFilter(provider.value)"
                        class=" py-3 text-gray-800 font-semibold text-xs leading-tight uppercase  hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                    >
                        {{ provider.name }}
                    </button>
                </div>
            </div>

            <DefaultTable class="mt-4" title="Histórico de vendas totais" :custom-filters="customFilters" endpoint="dashboard/sales" :columns="columns" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ApiService } from '@/services/api.service';

import TopPage from '@/components/TopPage.vue';
import Card from '@/components/cards/Card.vue';
import ColumnCard from '@/components/charts/ColumnCard.vue';
import DefaultTable from '@/components/tables/DefaultTable.vue';
import { Columns } from './columns';
export default {
    name: 'Sales',
    components: {
        TopPage,
        Card,
        ColumnCard,
        DefaultTable
    },
    data() {
        return {
            providers: null,
            customFilters: [
                {
                    name: 'provider_unit',
                    label: 'Parceiro',
                    value: null
                }
            ],
            cards: [
                {
                    id: 1,
                    title: 'vendas via afiliados',
                    value: '1.140,00',
                    endpoint: 'dashboard/sales/cards/affiliated-sales'
                },
                {
                    id: 2,
                    title: 'vendas via aplicativo',
                    value: '326,28',
                    endpoint: 'dashboard/sales/cards/affiliated-sales-app'
                },
                {
                    id: 3,
                    title: 'vendas de consultas',
                    value: '1.140,00',
                    endpoint: 'dashboard/sales/cards/affiliated-consult-sales'
                },
                {
                    id: 4,
                    title: 'vendas de exames',
                    value: '0',
                    endpoint: 'dashboard/sales/cards/affiliated-exam-sales'
                },
                {
                    id: 5,
                    title: 'vendas de produtos',
                    value: '0',
                    endpoint: 'dashboard/sales/cards/affiliated-product-sales'
                },
                {
                    id: 6,
                    title: '',
                    value: '0'
                }
            ],
            columns: Columns,
            salesChartSeries: [
                {
                    name: 'Consulta',
                    type: 'column',
                    yAxis: 0,
                    data: [],
                    endpoint: 'dashboard/sales/charts/consult-sales'
                },
                {
                    name: 'Exames',
                    type: 'spline',
                    data: [],
                    endpoint: 'dashboard/sales/charts/exam-sales'
                }
            ],
            salesChartSeries2: [
                {
                    name: 'Consulta',
                    type: 'column',
                    yAxis: 0,
                    data: [],
                    endpoint: 'dashboard/sales/charts/consult-sales'
                },
                {
                    name: 'Exames',
                    type: 'spline',
                    data: [],
                    compare: 'percent',
                    endpoint: 'dashboard/sales/charts/exam-sales'
                }
            ]
        };
    },
    async beforeRouteEnter(to, from, next) {
        const providers = await ApiService.get('dashboard/providers');
        next(vm => {
            let units = [];
            providers.data.forEach(provider => {
                provider.units.forEach(unit => {
                    units.push({ name: unit.nickname, value: unit.id });
                });
            });
            vm.providers = units;
        });
    },
    computed: {
        ...mapGetters('auth', ['loggedIn'])
    },
    mounted() {
        setTimeout(() => {
            this.customFilters[0].value = 300;
        }, 5000);
    },
    methods: {
        partnerFilter(value) {
            this.customFilters[0].value = value;
        }
    }
};
</script>

<style></style>
