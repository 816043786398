<template>
    <div class="p-2 bg-white border-2 rounded-2xl">
        <div class="p-4">
            <div class="border-2 mb-1 rounded-2xl w-6 flex border-gray-300"></div>
            <div class="text-gray-600 font-semibold">
                <h2 class="text-l flex">{{ title }}</h2>
            </div>
            <highcharts :options="chartOptions"></highcharts>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ApiService } from '@/services/api.service';
import { Chart } from 'highcharts-vue';

export default {
    name: 'Card',
    components: {
        highcharts: Chart
    },
    props: {
        title: {
            type: String,
            default: 'Título'
        },
        value: {
            type: String,
            default: 'Valor'
        },
        endpoint: {
            type: String,
            default: ''
        },
        series: {
            type: Array,
            default: () => []
        },
        type: {
            type: String,
            default: 'total'
        }
    },
    data() {
        return {
            mutableValue: this.value,
            loading: false,
            chartOptions: {
                credits: {
                    enabled: false
                },
                chart: {
                    zoomType: 'xy'
                },
                title: {
                    text: '',
                    align: 'left'
                },
                subtitle: {
                    // text: 'Source: ' + '<a href="https://www.yr.no/nb/historikk/graf/5-97251/Norge/Troms%20og%20Finnmark/Karasjok/Karasjok?q=2021"' + 'target="_blank">YR</a>'
                },
                xAxis: [
                    {
                        // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        // crosshair: true,
                        type: 'category',
                        uniqueNames: false,
                        lineWidth: 0,
                        minorGridLineWidth: 0,
                        lineColor: 'transparent'
                    }
                ],
                legend: {
                    align: 'right',
                    verticalAlign: 'top',
                    layout: 'horizontal',
                    x: 0,
                    y: 0
                },
                yAxis: {
                    // Primary yAxis
                    labels: {
                        // format: '{value}°C',
                        style: {
                            // color: '#FEA'
                        }
                    },
                    title: {
                        text: '',
                        style: {
                            // color: '#DFE'
                        }
                    }
                    // offset: 0
                },
                tooltip: {
                    shared: true
                },
                series: this.series
            }
        };
    },
    computed: {
        ...mapGetters('filters', ['period']),
        valueFormatted() {
            return this.mutableValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        }
    },
    watch: {
        period() {
            this.getData();
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            this.series.map(async serie => {
                if (typeof serie.endpoint === 'undefined' || serie.endpoint === '') {
                    return;
                }
                await ApiService.customRequest({ url: serie.endpoint, params: { period: this.period } })
                    .then(response => {
                        console.log(this.type);
                        if (this.type === 'total') {
                            serie.data = response.data.map(item => [item.date, item.quantity]);
                        } else if (this.type === 'percentage') {
                            let data = [];
                            response.data.forEach((item, index) => {
                                if (index > 0) {
                                    data.push([item.date, ((item.quantity - response.data[index - 1].quantity) / response.data[index - 1].quantity) * 100]);
                                }
                            });
                            serie.data = data;
                            // serie.data = response.data.map((item, index) => [item.date, item.quantity + item[index].quantity]);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });

            this.loading = true;
        }
    }
};
</script>

<style>
.load-wraper {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: rgb(211, 211, 211);
    z-index: 44;
    overflow: hidden;
    border-radius: 5px;
}
.activity {
    position: absolute;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    background-image: -moz-linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    background-image: -webkit-linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    animation: loading 1s infinite;
    z-index: 45;
}

@keyframes loading {
    0% {
        left: -45%;
    }
    100% {
        left: 100%;
    }
}
</style>
